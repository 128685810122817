<template>
  <v-app>
    <v-container>
      <div class="text-h6 mb-3 text-center">{{ $t('labels.account_register') }}</div>
      <v-card outlined max-width="400px" class="mx-auto">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="employee.name" autocomplete="off" :label="`${$t('labels.full_name')} *`" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="employee.phone" autocomplete="off" :label="`${$t('labels.phone_number')} *`" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="employee.password" autocomplete="off" outlined dense hide-details
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'"
                            :label="`${$t('labels.password')} *`" counter @click:append="showPassword = !showPassword"></v-text-field>
            </v-col>
            <v-col cols="12">
              <SelectCity name="id_city" :id-city="employee.id_city" @onChange="onChange" ref="selectCityRef" />
            </v-col>
            <v-col cols="12">
              <SelectCounty :id-city="employee.id_city" :id-county="employee.id_county" name="id_county" @onChange="onChange" />
            </v-col>
            <v-col cols="12">
              <SelectWard :id-city="employee.id_city" :id-county="employee.id_county" :id-ward="employee.id_ward" name="id_ward" @onChange="onChange" />
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="employee.address" autocomplete="off" :label="`${$t('labels.address')} *`" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="employee.pos_code" autocomplete="off" :label="`${$t('labels.pos_code')} *`" outlined dense hide-details></v-text-field>
            </v-col>
            <v-col cols="4" class="text-center">
              <ImageViewer v-if="employee.image_portrait" :url="employee.image_portrait" height="50px"/>
              <v-btn x-small color="info" @click="showImageUpload('image_portrait')" block>Ảnh khuôn mặt</v-btn>
            </v-col>
            <v-col cols="4" class="text-center">
              <ImageViewer v-if="employee.image_idcard_front" :url="employee.image_idcard_front" height="50px"/>
              <v-btn x-small color="warning" @click="showImageUpload('image_idcard_front')" block>CCCD trước</v-btn>
            </v-col>
            <v-col cols="4" class="text-center">
              <ImageViewer v-if="employee.image_idcard_back" :url="employee.image_idcard_back" height="50px"/>
              <v-btn x-small color="warning" @click="showImageUpload('image_idcard_back')" block>CCCD sau</v-btn>
            </v-col>
            <input type="file" ref="inputUploadFile" accept="image/*" @change="onInputFileChange" class="d-none">
            <v-spacer></v-spacer>
            <v-col cols="12">
              <v-btn block color="success" @click="register" :disabled="isDisabledBtn">{{ $t('labels.register') }}</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import {compress} from "image-conversion";
import moment from "moment";
import {httpClient} from "@/libs/http";

export default {
  name: "Register",
  components: {
    SelectCity: () => import('@/components/common/SelectCity'),
    SelectCounty: () => import('@/components/common/SelectCounty'),
    SelectWard: () => import('@/components/common/SelectWard'),
    ImageViewer: () => import('@/components/common/ImageViewer'),
  },
  data: () => ({
    employee: {
      name: null,
      phone: null,
      password: null,
      address: null,
      id_city: null,
      id_county: null,
      id_ward: null,
      pos_code: null,

      image_portrait: null,
      image_idcard_front: null,
      image_idcard_back: null,
    },
    uploadType: null,
    showPassword: false,
    isLoading: false
  }),
  computed: {
    isDisabledBtn() {
      if (this.isLoading) {
        return true
      }
      if (!this.employee.phone || (!this.isPhoneValid(this.employee.phone) && !this.isEmailValid(this.employee.phone))) {
        return true
      }
      if (!this.employee.password || this.employee.password.length < 6) {
        return true
      }

      const keys = Object.keys(this.employee)
      let isInvalid = false
      keys.forEach(k => {
        if (!this.employee[k]) {
          isInvalid = true
        }
      })
      return isInvalid
    }
  },
  methods: {
    async onInputFileChange(e) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true
      let files = e.target.files || e.dataTransfer.files
      if (files && files[0]) {
        const filePath = await this.uploadFile(files[0])
        if (filePath) {
          this.employee[this.uploadType] = `${process.env.VUE_APP_FILE_CDN_URL}/${filePath}`
        }
        this.$refs.inputUploadFile.value = null
      }
      this.isLoading = false
    },
    async uploadFile(file) {
      const resBlob = await compress(file,{
        quality: 0.85,
        type: "image/jpeg",
      })
      const newFile = new File([resBlob], `${moment().unix()}_${this.generateRandomString(10)}.jpg`,{type: "image/jpeg"})
      let fd = new FormData();
      fd.append("file", newFile);

      const {data} = await httpClient.post(`/upload-image`, fd)
      return data
    },
    async register() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.loading'));
        return false
      }
      this.isLoading = true

      try {
        await httpClient.post('/employee-register', this.employee)
        this.isLoading = false
        this.$vToastify.success(this.$t('messages.employee_register_success'))
        const employee = {
          name: null,
          phone: null,
          password: null,
          address: null,
          id_city: null,
          id_county: null,
          id_ward: null,
          pos_code: null,

          image_portrait: null,
          image_idcard_front: null,
          image_idcard_back: null,
        }
        this.employee = {...employee}
      } catch (e) {
        this.isLoading = false
        const errMsg = e.response && e.response.data && e.response.data.error && e.response.data.error.message || null
        this.$vToastify.error(errMsg)
      }
    },
    showImageUpload(type) {
      this.uploadType = type
      this.$refs.inputUploadFile.click()
    },
    onChange(item) {
      this.employee = {...this.employee, [item.name]: item.value}
    },
  }
}
</script>

<style scoped>

</style>
